import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
//import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

function Settings() {

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      fullScreen
      open
      id={"settings"}
      onKeyDown={checkKeys}
      TransitionComponent={Transition}
    >
      <AppBar className={useStyles().appBar}>
        <Toolbar>
          <Typography variant="h6" className={useStyles().title}>
            Settings
          </Typography>
          {/* <Button color="inherit">save</Button> */}
        </Toolbar>
      </AppBar>
      <List>
        <ListItem button>
          <ListItemText
            primary="Network address"
            secondary="https://....:PORT/"
          />
          <TextField
            defaultValue={localStorage.getItem("network")}
            onChange={setSettings}
            id={"selectNetwork"}
			autofocus
          >
          </TextField>
        </ListItem>

        <ListItem button>
          <ListItemText
            primary="Contract Address"
            secondary="Should look like 0x..."
          />
          <TextField
            defaultValue={localStorage.getItem("contract")}
            onChange={setSettings}
            id={"selectContract"}
          >
          </TextField>
        </ListItem>

        <ListItem button>
          <ListItemText
            primary="Account Address"
            secondary="Should look like 0x..."
          />
          <TextField
            defaultValue={localStorage.getItem("address")}
            onChange={setSettings}
            id={"selectAddress"}
          >
          </TextField>
        </ListItem>
        <Divider />
      </List>
    </Dialog>
  );
}

function setSettings() {
  var address = document.getElementById("selectAddress").value;
  var network = document.getElementById("selectNetwork").value;
  var contract = document.getElementById("selectContract").value;

  localStorage.setItem("address", address ?? '');
  localStorage.setItem("network", network ?? '');
  localStorage.setItem("contract", contract ?? '');
  return;
}

function checkKeys(e) {
  switch (e.keyCode) {
    case 13:
      console.log("back");
      window.location.reload();
      break;
    default:
      console.log("other");
  }
}

export default Settings;