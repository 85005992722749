import React, { useState } from 'react';
import './App.css';

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';

//import ConnectC from "./ConnectC/ConnectC.js";
// import EncData from "./ConnectC/ConnectC.js";
import {StoreData} from "./ConnectC/ConnectC.js";

import StoredCerts from "./Tables/StoredCerts.js";
import {uniCode} from './ConnectC/config.js'
// import AES from 'crypto-js/aes';
// import Utf8 from 'crypto-js/enc-utf8';

import Settings from "./Settings/Settings.js";

function App() {
	if (localStorage.getItem("address")) {
		return <div className="App">{DrawPage()}</div>
	} else {
	  return <Settings />;
	}
  }

function DrawPage() {
	//console.log(Utf8.stringify(AES.decrypt('U2FsdGVkX1/XLtQeqcwsW+o6rKncA8f2iqb5+yhyz6mpOp6HEXizh254gjFISpb4', '1234')))
	//Data to be encrypted
	let propertyData = {
		CID:"PMS1",
		dataToEnc: {
			parcel:"RW",
			building:"JJ000JJ",
			dataConverted:"0/0",
			fullAddress:"0000",
			desc:"0000",
			area:"0m2",
			coordinates:"0.000,0.000",
		},
		profilePhoto: "" //Profile photo
	}

	//Other data
	
	let joinedData = Object.values(propertyData.dataToEnc).map(el => {return el}).join(';');
	//console.log(joinedData);
	//NOTE: Hardcode issuing university or link with university name on smart contract
	const ContractField = withStyles({
		root: {
	 		margin: '3px'
		}
	})(TextField);

	const [storedCerts, setStoredCerts] = useState(StoredCerts());

	function handleFile(e) {
        const file = e.target.files[0];
        const reader = new FileReader();

		//NOTE: CHECK FILE SIZE BEFORE ENCODING
        reader.readAsDataURL(file);
    
        reader.addEventListener("load", function() {
			const encodedData = Buffer.from(reader.result).toString('base64');
			propertyData.profilePhoto = encodedData;
          }, false);
	}
	
	return(<div className="connecting">
			<Box m={2}>
			<ContractField className={"MediumTextField"} type='number' label='Certificate ID' name='certID' variant='outlined' onChange={(e) => {propertyData.CID = (uniCode + e.target.value)}}/>
			<ContractField className={"MediumTextField"} type='text' label='Parcel NO.' name='parcel' variant='outlined' onChange={(e) => {propertyData.dataToEnc.parcel = (e.target.value)}}/>
			<ContractField className={"MediumTextField"} type='text' label='Building NO.' name='building' variant='outlined' onChange={(e) => {propertyData.dataToEnc.building = (e.target.value)}}/>
			<ContractField className={"MediumTextField"} type='text' label='Data from converted import' name='dataConverted' variant='outlined' onChange={(e) => {propertyData.dataToEnc.dataConverted = (e.target.value)}}/>
			<ContractField className={"MediumTextField"} type='text' label='Full Address (Apt/Floor/No)' name='fullAddress' variant='outlined' onChange={(e) => {propertyData.dataToEnc.fullAddress = (e.target.value)}}/>
			<ContractField className={"MediumTextField"} type='text' label='Description' name='desc' variant='outlined' onChange={(e) => {propertyData.dataToEnc.desc = (e.target.value)}}/>
			<ContractField className={"MediumTextField"} type='text' label='Area (m2)' name='area' variant='outlined' onChange={(e) => {propertyData.dataToEnc.area = (e.target.value)}}/>
			<ContractField className={"MediumTextField"} type='text' label='Coordinates (Lat. Long.)' name='coordinates' variant='outlined' onChange={(e) => {propertyData.dataToEnc.coordinates = (e.target.value)}}/>
			<br/><Input id="file" type="file" accept=".webp" onChange={(e) => {handleFile(e)}} />
			{/* <p><Button variant="contained" type="submit" onClick={(e) => {
				joinedData = Object.values(propertyData.dataToEnc).map(el => {return el}).join(';');
				EncData([propertyData.CID], [joinedData], [prompt("EnterPass")])}}>Deploy</Button></p> */}
			<p><Button variant="contained" type="submit" onClick={(e) => {
				joinedData = Object.values(propertyData.dataToEnc).map(el => {return el}).join(';');
				console.log(joinedData);
				StoreData(propertyData.CID, joinedData, prompt("EnterPass"), propertyData.profilePhoto);
				setStoredCerts(StoredCerts());
			}}>Store Locally</Button></p>
			{storedCerts ? storedCerts : ''}
			</Box>
	</div>
	)
}

export default App;
