import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ImportCerts from './Tables/ImportCerts';


const theme = createMuiTheme({
  palette: {
    type: "light",
    background: {
      default: "#c7c7c7",
    },
    primary: {
      main: "#0051f2",
    },
    secondary: {
      main: "#ff9800"
    }
  }
})

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <React.StrictMode>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route path="/" component={App} exact/>
          <Route path="/imported" component={ImportCerts} />
      </Switch>
    </BrowserRouter>
    </React.StrictMode>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
