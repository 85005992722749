export const contractAbi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_acd",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "_acdName",
				"type": "string"
			}
		],
		"name": "addAcd",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes[]",
				"name": "_nrDiplome",
				"type": "bytes[]"
			},
			{
				"internalType": "string[]",
				"name": "_rawData",
				"type": "string[]"
			},
			{
				"internalType": "string[]",
				"name": "_data",
				"type": "string[]"
			},
			{
				"internalType": "enum certificateContract.State[]",
				"name": "_state",
				"type": "uint8[]"
			},
			{
				"internalType": "string[]",
				"name": "_photoHash",
				"type": "string[]"
			}
		],
		"name": "addCert",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_uni",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "_uniName",
				"type": "string"
			}
		],
		"name": "addUni",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_master",
				"type": "address"
			}
		],
		"name": "setMaster",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_acd",
				"type": "address"
			}
		],
		"name": "switchAcd",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_uni",
				"type": "address"
			}
		],
		"name": "switchUni",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "certCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"name": "certs",
		"outputs": [
			{
				"internalType": "string",
				"name": "rawData",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "encData",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "created",
				"type": "uint256"
			},
			{
				"internalType": "enum certificateContract.State",
				"name": "state",
				"type": "uint8"
			},
			{
				"internalType": "string",
				"name": "photoHash",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_acd",
				"type": "address"
			}
		],
		"name": "getAcd",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			},
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getAcds",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_acd",
				"type": "address"
			}
		],
		"name": "isAcd",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_uni",
				"type": "address"
			}
		],
		"name": "isUni",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "master",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "unis",
		"outputs": [
			{
				"internalType": "address",
				"name": "uniAddress",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "uniName",
				"type": "string"
			},
			{
				"internalType": "bool",
				"name": "uniState",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]