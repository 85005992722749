import React, { useState } from 'react';

import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import EncData from "../ConnectC/ConnectC.js";
import AES from 'crypto-js/aes';
// import Utf8 from 'crypto-js/enc-utf8';

import { ipfsGateway } from '../ConnectC/config.js';

const ipfsClient = require('ipfs-http-client')

function ImportCerts() {
  const [certsData, setCertsData] = useState(null);

  function handleFile(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsText(file);

    reader.addEventListener("load", function () {
      setCertsData(JSON.parse(reader.result));
    }, false);
  }

  return (
    <Box m={2} justifyContent="center" style={{ textAlign: 'center' }}>
      <Input id="file" type="file" onChange={(e) => { handleFile(e) }} />
      {!certsData ? '' :
        <div>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Certificate ID</TableCell>
                  <TableCell align="right">Parcel No.</TableCell>
                  <TableCell align="right">Building No.</TableCell>
                  <TableCell align="right">Data from converted import</TableCell>
                  <TableCell align="right">Apt/Floor/No.</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Area (m2)</TableCell>
                  <TableCell align="right">Lat. Long. Coordinates</TableCell>
                  <TableCell align="right">Key</TableCell>
                  <TableCell align="right">Photo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {certsData.map((row, i) => (
                  <TableRow key={row[0]}>
                    <TableCell component="th" scope="row">
                      {row[0]}
                    </TableCell>

                    {row[1].split(';').map((encRow, i) => (
                      <TableCell key={encRow} align="right">{encRow}</TableCell>
                    ))}

                    <TableCell align="right">{row[2]}</TableCell>
                    <TableCell align="right"><img src={atob(row[3])} height={64} alt="" /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p><Button variant="contained" type="submit" onClick={(e) => { deployContracts(certsData) }}>Deploy Contracts</Button></p>
        </div>
      }
    </Box>
  )
}

function deployContracts(certs) {
  //console.log(certs);
  let CIDs = [];
  let encData = [];
  let passwords = [];
  let photos = [];
  let photoHashes = [];

  for (let i = 0; i < certs.length; i++) {
    CIDs.push(certs[i][0]);
    encData.push(certs[i][1]);
    passwords.push(certs[i][2]);
    let encPhoto = AES.encrypt(atob(certs[i][3]), certs[i][2]).toString();
    photos.push(encPhoto);
  }

  ipfsUpload(photos).then((hashes) => {
    for (let i = 0; i < hashes.length; i++) {
      photoHashes[i] = hashes[i];
    }
    EncData(CIDs, encData, passwords, photoHashes);
    //console.log([CIDs, encData, passwords, photoHashes]);
  });
}

async function ipfsUpload(photos) {
  const ipfs = ipfsClient(ipfsGateway);

  // const chunks = [];
  // for await (const chunk of ipfs.cat("QmcqnXMK3EmuwhvwGNXHtZndtBk5Gdi7vFK2jX9nuEp48r")) {
  //     let chunkString = new TextDecoder().decode(chunk);
  //     chunks.push(chunkString);
  // }
  // console.log(AES.decrypt(chunks.join(''), "1234").toString(Utf8));

  let photoHashes = [];

  for (let i = 0; i < photos.length; i++) {
    let { cid } = await ipfs.add(photos[i], { onlyHash: false });
    photoHashes[i] = cid.string;
  }

  // let { cid } = await ipfs.add(photos, {onlyHash: true});
  // return cid.string;

  return photoHashes;
}

export default ImportCerts;