//////******THIS IS THE CONFIG FILE FOR THE APP******//////

//Address where the contract is deployed
export const contractAddress = "";

//Network where the contract is deployed
export const network = "https://gw.bcerts.al/";

//Address to use in order to sign transactions
export const sendAddress = "";

//University code
export const uniCode = "PMS";
export const ipfsGateway = 'https://ig.bcerts.al';