import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

function StoredCerts(action) {
  const certsBuffer = JSON.parse(localStorage.getItem('certsBuffer'));

  if (!certsBuffer)
    return null;

  const encData = certsBuffer[0][1]?.split(';');
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Certificate ID</TableCell>
              <TableCell align="right">Parcel No.</TableCell>
              <TableCell align="right">Building No.</TableCell>
              <TableCell align="right">Data from converted import</TableCell>
              <TableCell align="right">Apt/Floor/No.</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Area (m2)</TableCell>
              <TableCell align="right">Lat. Long. Coordinates</TableCell>
              <TableCell align="right">Encryption Key</TableCell>
              <TableCell align="right">Photo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certsBuffer.map((row, i) => (
              <TableRow key={row[0]}>
                <TableCell component="th" scope="row">
                  {row[0]}
                </TableCell>

                {encData.map((encRow, i) => (
                  <TableCell key={encRow} align="right">{encRow}</TableCell>
                ))}

                <TableCell align="right">{row[2]}</TableCell>
                <TableCell align="right"><img src={atob(row[3])} height={64} alt="" /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p><Button variant="contained" type="submit" onClick={(e) => { saveData() }}>Export</Button></p>
    </div>
  );
}

function saveData() {
  let a = document.createElement("a");
  let file = new Blob([localStorage.getItem("certsBuffer")], { type: "application/json" });
  a.href = URL.createObjectURL(file);
  let date = new Date();
  a.download = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}.json`;
  a.click();
  localStorage.removeItem("certsBuffer");
  window.location.reload();
}

export default StoredCerts;