import Web3 from 'web3'
//import { useState, useEffect } from 'react';

import {contractAbi} from './abi.js'
//import {contractAddress, network, sendAddress} from './config.js'

import AES from 'crypto-js/aes';

let network = localStorage.getItem("network");
let contractAddress = localStorage.getItem("contract");
let sendAddress = localStorage.getItem("address");

const web3 = new Web3(new Web3.providers.HttpProvider(network));

function ConnectC() {
	
	// Initialize Web3

	// Set Account
	web3.eth.defaultAccount = web3.eth.accounts[0];

	// Set the Contract
	let contract = new web3.eth.Contract(contractAbi, contractAddress);
	//console.log(contract);
	//const data = contract.methods.certs(id).call()
	return contract;
}

function EncData(CID, data, pass, photoHashes) {
	//NOTE: Validate data
    //NOTE: Validate if address can add certificates

    let encrypted = [];
    for(let i = 0; i < CID.length; i++) {
        encrypted.push(AES.encrypt(data[i], pass[i]).toString());
        //console.log(data[i]);
    }
	ConnectC().methods.certCount().call().then(r => console.log(r));
	
	//Connecting to contract
	let addressKey = prompt("Enter your private key in order to sign the transaction");
	let addJobABI = ConnectC().methods.addCert(CID.map(el => {return '0x'+ascii_to_hexa(el)}), CID.map(el => {return "PublicData"}), encrypted, CID.map(el => {return 1}), photoHashes).encodeABI();

    web3.eth.estimateGas({
        "from"      : sendAddress,   
        "to"        : contractAddress,     
        "data"      : addJobABI
   }).then(res => console.log(res));

	let tx = {
        from: sendAddress,
        to: contractAddress,
        // gasPrice: 1,
        gas: 18000000, ///Calculate from attaching to geth console and using "eth.getBlock('latest')". Use the latest gas limit.
        data: addJobABI
	};

    web3.eth.accounts.signTransaction(tx, addressKey).then(signed => {
        let tran = web3.eth.sendSignedTransaction(signed.rawTransaction);

        //tran.on('confirmation', (confirmationNumber, receipt) => {
        //    console.log('confirmation: ' + confirmationNumber);
        //});

        //tran.on('transactionHash', hash => {
        //    console.log('hash');
        //    console.log(hash);
        //});

        tran.on('receipt', receipt => {
            alert("Receipt Available\nCheck console");
            console.log(receipt);
        });

        tran.on('error', error => {console.log(error);alert("Transaction failed")});
    })
}

function StoreData(CID, data, pass, photo) {
    let certsBuffer = JSON.parse(localStorage.getItem('certsBuffer')) ? JSON.parse(localStorage.getItem('certsBuffer')) : [];
    certsBuffer.push([CID, data, pass, photo]);
    localStorage.setItem('certsBuffer', JSON.stringify(certsBuffer));
    console.log(JSON.parse(localStorage.getItem('certsBuffer')));
}

function ascii_to_hexa(str) {
	var arr1 = [];
	for (var n = 0, l = str.length; n < l; n ++) {
		var hex = Number(str.charCodeAt(n)).toString(16);
		arr1.push(hex);
	}
	return arr1.join('');
}

//export default ConnectC;
export default EncData;
export {StoreData};